import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// Angular Material
import { MatSliderModule } from '@angular/material/slider';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';

// Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BackendComponent } from './backend/backend.component';
import { HyperfollowComponent } from './hyperfollow/hyperfollow.component';
import { ImprintComponent } from './imprint/imprint.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { AngularTiltModule } from 'angular-tilt';
import { MailSignatureComponent } from './mail-signature/mail-signature.component';
import { LoginComponent } from './backend/login/login.component';
import { DatabaseComponent } from './backend/database/database.component';
import { CreatePressDatasetComponent } from './backend/database/create-press-dataset/create-press-dataset.component';
import { FetchPressDatasetComponent } from './backend/database/fetch-press-dataset/fetch-press-dataset.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RadioComponent } from './radio/radio.component';

@NgModule({
  declarations: [
    AppComponent,
    BackendComponent,
    HyperfollowComponent,
    ImprintComponent,
    LoadingSpinnerComponent,
    MailSignatureComponent,
    LoginComponent,
    DatabaseComponent,
    CreatePressDatasetComponent,
    FetchPressDatasetComponent,
    RadioComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularTiltModule,
    BrowserAnimationsModule,
    //Angular Material
    MatSliderModule,
    MatInputModule,
    MatButtonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
