<!-- Logo Image old
<img src="../../assets/svg/110100100.global-logo.svg" class="logo">
-->
<!-- Logo Image -->
<a routerLink="/" class="home-logo" title="Home"></a>
<div class="row">
    <div class="col-sm-12">
        <div class="container">
            <div class="row">
                <div class=" col-2 col-sm-1 col-md-2 col-lg-2 col-xl-2"></div>
                <div class=" col-8 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                    <!-- Main Artwork -->
                    <!-- uses jquery, if it is not working, maby run: npm install @types/jquery --save -->
                    <a href="https://110100100.bandcamp.com/album/the-garden-will-grow-without-me" target="_blank" >
                        <img src="../../assets/img/jagua_1500.jpg" class="img-fluid" alt="JAGUA - The Garden Will Grow Without Me" aTilt>
                    </a>
                </div>
                <div class="col-0 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                <div class="releases"></div>
<!--            <a href="#" target="_blank"><h1 class="releases">/Radio</h1></a> -->
                    <a href="https://110100100.bandcamp.com/music" target="_blank"><h1 class="radio">/Releases</h1></a>
                    <hr class="hr-line">
                    <div class="container-fluid" class="link-icon-container">
                        <div class="row">
                            <div class="col-2 col-sm-3">
                                <a href="https://soundcloud.com/110100100global" target="_blank" class="soundcloud-icon"></a>
                            </div>
                            <div class="col-2 col-sm-2" style="padding: 0;">
                                <a href="https://www.youtube.com/channel/UCWRHxNEZLdvR2FrYL8trh9w" target="_blank" class="youtube-icon"></a>
                            </div>
                            <div class="col-2 col-sm-2" style="padding: 0; margin: 0;">
                                <a href="https://www.instagram.com/110100100.global/" target="_blank" class="instagram-icon"></a>
                            </div>
                            <div class="col-2 col-sm-2" style="padding: 0; margin: 0;">
                                <a href="https://open.spotify.com/user/31vamej62nhgpmtvztrhhoe5pf7i?si=55962847670143bd&nd" target="_blank" class="spotify-icon"></a>
                            </div>
                            <div class="col-4 col-sm-3"></div>
                        </div>
                    </div>
                    <a href="https://discord.com/invite/rTqByEr" target="_blank"><p class="links">/discord</p></a>
                    <a href="https://www.reddit.com/r/110100100global/" target="_blank"><p class="links">/reddit</p></a>
                    <a href="mailto:jakob@110100100.global"><p class="links">/contact</p></a>
                    <p class="copyright">110100100.global &#169; 2024</p>
                </div>
                <div class="col-2 col-sm-1 col-md-3 col-lg-3 col-xl-4"></div>
            </div>   
        </div>
    </div>
</div>
<a routerLink="/imprint" style="bottom: 0; right: 15px; position: fixed;"><p>imprint</p></a>

<script src="node_modules/jquery/dist/jquery.min.js"></script>
<script src="node_modules/hover3d/dist/js/jquery.hover3d.min.js" ></script>