import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-hyperfollow',
  templateUrl: './hyperfollow.component.html',
  styleUrls: ['./hyperfollow.component.css']
})
export class HyperfollowComponent implements OnInit {

  constructor() { 
  }

  ngOnInit(): void {
  }

  //Testing() {
  //  var name = $("#txtName").val();
  //  alert(name);
  //}
}
