<!--Press Office Database -->
<!-- blogs -->
<div class="row">
    <div class="col-12">
        <h2>Press Office</h2>
        <p>Bolgs</p>
        <p>name</p>
        <p>website</p>
        <p>genre</p>
        <p>contact-person</p>
        <p>e-mail</p>
        <p>adress</p>
    </div>
</div>