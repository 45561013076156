<div class="container">
    <div class="row">
        <div class="col-12">
            <form class="press-dataset-form">
                <!-- name of the promo company-->
                <mat-form-field class="press-dataset-full-width" appearance="fill" color="accent">
                    <mat-label>Company Name</mat-label>
                    <input matInput #message maxlength="256" placeholder="Ex. 110100100.global">
                    <mat-hint align="start"><strong>Name of press company</strong> </mat-hint>
                    <!-- assign sql length-->
                    <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                </mat-form-field>
                <!-- Country the Press Company is placed-->
                <mat-form-field class="press-dataset-full-width" appearance="fill" color="accent">
                    <mat-label>Country</mat-label>
                    <input matInput #message maxlength="256" placeholder="Ex. Germany">
                    <mat-hint align="start"><strong>Country of press company</strong> </mat-hint>
                    <!-- assign sql length-->
                    <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                </mat-form-field>
                <!-- type of the promo company for example: radio, blog, youtube-channel, television-->
                <mat-form-field class="press-dataset-full-width" appearance="fill">
                    <mat-label>type</mat-label>
                    <input matInput #message maxlength="256" placeholder="Ex. blog, radio, youtube-channel">
                    <mat-hint align="start"><strong>type of press company</strong> </mat-hint>
                    <!-- assign sql length-->
                    <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                </mat-form-field>
                <!-- genres the channel supports or is known for-->
                <mat-form-field class="press-dataset-full-width" appearance="fill">
                    <mat-label>gernes</mat-label>
                    <input matInput #message maxlength="256" placeholder="Ex. techno, ambient, pop">
                    <mat-hint align="start"><strong>Gernes the Company supports</strong> </mat-hint>
                    <!-- assign sql length-->
                    <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                </mat-form-field>
                <!-- internet link to the companies website-->
                <mat-form-field class="press-dataset-full-width" appearance="fill">
                    <mat-label>website</mat-label>
                    <input matInput #message maxlength="256" placeholder="Ex. https://110100100.global">
                    <mat-hint align="start"><strong>Companies website</strong> </mat-hint>
                    <!-- assign sql length-->
                    <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                </mat-form-field>
                <!-- name of the contact person -->
                <mat-form-field class="press-dataset-full-width" appearance="fill">
                    <mat-label>contact person</mat-label>
                    <input matInput #message maxlength="256" placeholder="Ex. Rita Poppen">
                    <mat-hint align="start"><strong>First and last name of the contact person</strong> </mat-hint>
                    <!-- assign sql length-->
                    <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                </mat-form-field>
                <!-- e-mail of the contact person -->
                <mat-form-field class="press-dataset-full-width" appearance="fill">
                    <mat-label>E-Mail</mat-label>
                    <input matInput #message maxlength="256" placeholder="Ex. press@110100100.global">
                    <mat-hint align="start"><strong>Contact E-Mail Adress</strong> </mat-hint>
                    <!-- assign sql length-->
                    <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
                </mat-form-field>
                <!-- add to database-->
                <div class="press-dataset-button-row">
                    <button mat-stroked-button>add</button>
                </div>
            </form>
        </div>
    </div>
</div>