<!-- Logo Image -->
<a routerLink="/" class="home-logo" title="Home"></a>
<div class="row">
    <div class="col-12">
        <div class="container">
            <div class="row">
                <div class="col-12 phone">
                    <p class="line-height">Angaben gemäß § 5 TMG:</p>
                    <p class="tab line-height">// Jakob Neugebauer</p>
                    <p class="double-tab line-height">// Zschochersche Straße 30</p>
                    <p class="double-tab line-height">// 04229 Leipzig</p>
                    <br>
                    <p class="line-height">Contact</p>
                </div>
            </div>   
        </div>
        <div class="container">
            <div class="row">
                <div class="col-5">
                    <p class="tab line-height">// Phone:</p>
                    <p class="tab line-height">// General:</p>
                    <p class="tab line-height">// Press:</p>
                 <!--   <p class="tab line-height">// Tech:</p> -->
                </div>
                <div class="col-7">
                    <p class="tab line-height">+49 174 4236831</p>
                    <p class="tab line-height">jakob@110100100.global</p>
                    <p class="tab line-height">press@110100100.global</p>
                    <!-- <p class="tab line-height">lukas@110100100.global</p> -->
                </div>
            </div>
        </div>
    </div>
</div>