<div class="mail-signature">
    <div class="logo">
        <img src="https://110100100.global/assets/img/110100100global.png">
    </div>
    <div class="contact-person">
        <p style="line-height: 0.5em;">Lukas Rosenkranz</p>
        <p style="line-height: 0.5em;">Tech Support</p>
        <br>
        <p style="line-height: 0.5em;">phone: <a>+49 157 56771819</a></p>
        <p style="line-height: 0.5em;">mail: <a>lukas@110100100.global</a></p>
    </div>
</div>
