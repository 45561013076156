import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fetch-press-dataset',
  templateUrl: './fetch-press-dataset.component.html',
  styleUrls: ['./fetch-press-dataset.component.css']
})
export class FetchPressDatasetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
