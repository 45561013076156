<!-- TODO -->
<!--  - transfer this project into a new one called 110100100.global
          -> this will be the official 110100100.global project
          -> add git version controll
          -> nice to have: upload it somewhere for example on github
-->
<!--  - change the cover image so the loading strategie is responsive -->
<!--  - add google analytics                                          -->
<!--  - add cookie popup                                              -->
<!--  - create barcode prtfolio website                               -->

<router-outlet></router-outlet>

<!--
<app-mail-signature></app-mail-signature>

<app-create-press-dataset></app-create-press-dataset>

<app-radio></app-radio>
-->