<!-- Logo -->
<!--
<img src="../../assets/img/110100100global.png" class="logo">
-->

<!-- Loding Spinner Gif-->
<div class="row">
    <div class="col-12 d-flex justify-content-center">

        <img alt="Loading..." src="assets/gif/grid-globe.gif" class="spinner">
    </div>
</div>
<!-- activate javascript Notification -->
<div class="row">
    <div class="col-12 d-flex justify-content-center">
        <!-- Because this is an Angular App, Javascript needs to be activated -->
        <!-- Some people add Javascript-bloger Plugins to theis Browser because of Secruity Reasons -->
        <noscript>
            <p class="loading-notification">- please enable Javascript - </p>
        </noscript>
    </div>
</div>